import { BaseEnum } from "./baseEnum";

const listBenefitTypeEnum: Array<BenefitTypeEnum> = [];

export type BenefitType =
  | "MealVoucher"
  | "FoodVoucher"
  | "DentalPlan"
  | "DaycareAssistance";

export class BenefitTypeEnum extends BaseEnum {
  static TransportationAllowance = new BenefitTypeEnum(
    1,
    "TransportationAllowance",
    "Vale-Transporte"
  );
  static MealVoucher = new BenefitTypeEnum(2, "MealVoucher", "Vale-Refeição");
  static FoodVoucher = new BenefitTypeEnum(
    3,
    "FoodVoucher",
    "Vale-Alimentação"
  );
  static HealthPlan = new BenefitTypeEnum(4, "HealthPlan", "Plano de Saúde");
  static DentalPlan = new BenefitTypeEnum(
    5,
    "DentalPlan",
    "Plano Odontológico"
  );
  static DaycareAssistance = new BenefitTypeEnum(
    6,
    "DaycareAssistance",
    "Auxilio Creche"
  );
  static LifeInsurance = new BenefitTypeEnum(
    7,
    "LifeInsurance",
    "Seguro de Vida"
  );

  constructor(public id: number, public value: string, public label: string) {
    super(id, value, label);
    listBenefitTypeEnum.push(this);
  }

  public static buildListEnum(): Array<BaseEnum> {
    return listBenefitTypeEnum;
  }
}

import { Injectable } from "@angular/core";
import { FileService } from "@core/services/file.service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "services/http.service";
import { DocumentMockupModel } from "services/models/documents/documentmockup.model";
import { DocumentMockupVariableModel } from "services/models/documents/documentmockupVariable.model";
import { Response } from "services/models/response";
import { ServiceBase } from "services/servicebase";

@Injectable()
export class DocumentMockupService extends ServiceBase<DocumentMockupModel> {
	constructor(http: HttpService, private file: FileService) {
		super("documentmockups", http, environment.apiUrl);
	}

	createEntity(input: any): DocumentMockupModel {
		return DocumentMockupModel.create(input);
	}

	getVariables(): Observable<Response<DocumentMockupVariableModel[]>> {
		return this.http
			.get('variables/odata', null, null, null, true)
			.pipe(
				map((res) => {
					const response = new Response<DocumentMockupVariableModel[]>();

					response.data = res.body.map((pp) => DocumentMockupVariableModel.create(pp) as DocumentMockupVariableModel);
					response.count = res.headers.get('x-count');

					return response;
				})
			);
	}

	saveTemplate(data: any) {
		return new Observable<any>((observer) => {
			this.http.post(`${this.endpoint}`, data).subscribe(response => {
				observer.next(response);
				observer.complete()
			})
		})
	}

	handleOpenFilePdf(id: string) {
		this.http.get(`${this.endpoint}/${id}/preview`).subscribe(({ body }) => {
			const type = "pdf";
			const blob = this.file.base64ToBlob(body.fileContents);

			this.file.saveFile(blob, `${body.fileDownloadName}.${type}`);

			return blob;
		}, (error) => {
			console.log('error service', error)
		})
	}
}

import { UppercaseDirective } from './../../../@core/directives/uppercase.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NgbDropdownModule,
  NgbModule,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TableListAbstractComponent } from './components/table-list-abstract/table-list-abstract.component';
import { TableTitleComponent } from './components/table/table-title/table-title.component';
import { TableHeaderFilterComponent } from './components/table/table-header-filter/table-header-filter.component';
import { CoreDirectivesModule } from '@core/directives/directives';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PasswordComponent } from './components/password-strength/password.component';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { NgxMaskModule } from 'ngx-mask';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AdmissionFormBaseComponent } from './hiring/admission-form-base/admission-form-base.component';
import { AutoFocus } from './autofocus.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { ErrorMsgModule } from "app/layout/components/error-msg/error-msg.module";
import { FormDebugComponent } from './components/form-debug/form-debug.component';
import { ScrollTopComponent } from './components/scroll-top/scroll-top.component';
import { VacationsScheduleModalListComponent } from './corporate/modals/vacations-schedule-modal-list/vacations-schedule-modal-list.component';
import { CreateVacationScheduleModalComponent } from './corporate/modals/create-vacation-schedule-modal/create-vacation-schedule-modal.component';

@NgModule({
  imports: [
    NgbPaginationModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    NgbModule,
    ErrorMsgModule,
    NgSelectModule,
    PerfectScrollbarModule,
    NgxMaskModule.forRoot(),
    CoreDirectivesModule,
    CorePipesModule,
  ],
  declarations: [
    AdmissionFormBaseComponent,
    TableListAbstractComponent,
    TableTitleComponent,
    UppercaseDirective,
    PasswordComponent,
    TableHeaderFilterComponent,
    PaginatorComponent,
    FormDebugComponent,
    AutoFocus,
    VacationsScheduleModalListComponent,
    CreateVacationScheduleModalComponent,
    ScrollTopComponent
  ],
  exports: [
    AdmissionFormBaseComponent,
    TableListAbstractComponent,
    TableTitleComponent,
    TableHeaderFilterComponent,
    PasswordComponent,
    UppercaseDirective,
    PaginatorComponent,
    FormDebugComponent,
    NgSelectModule,
    AutoFocus,
    VacationsScheduleModalListComponent,
    ScrollTopComponent
  ],
})
export class SharedModule { }

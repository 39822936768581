import { isValidDate } from './../../../../utils/helps';
import { ObjectMapper } from 'json-object-mapper';
import moment from 'moment';
import { IModel } from '../IModel';
import { Model } from '../model';
import { ApplicantModel } from './applicant.model';
import { ApplicantDependentModel } from './applicantDependent.model';

export class ApplicantFormModel
  extends ApplicantModel
  implements IModel<ApplicantFormModel>
{
  maritalStatus: number = 1;
  sex: number = 1;
  birthDate: string = '';
  dependentNumber: number = 0;
  racialDistinction: number = 9;
  schooling: number = 35;
  nationality: string = '';
  birthplace: string = '';
  birthState: string = '';
  fathersName: string = '';
  mothersName: string = '';
  bankNumber: string = '';
  bankAgency: string = '';
  bankAccountNumber: string = '';
  bankAccountDigit: string = '';
  zipCode: string = '';
  street: string = '';
  addressComplement: string = '';
  houseNumber: string = '';
  neighborhood: string = '';
  city: string = '';
  state: string = '';
  contact1: string = '';
  contact2: string = '';
  electoralCard: string = '';
  electoralSection: string = '';
  electoralZone: string = '';
  rg: string = '';
  rgIssuer: string = '';
  rgState: string = '';
  rgDate: string = '';
  pis: string = '';
  ctpsNumber: string = '';
  ctpsSerie: string = '';
  ctpsState: string = '';
  ctpsIssueDate: string = '';
  militaryDischarge: string = '';
  applicantId: string | undefined = undefined;
  dependents: ApplicantDependentModel[] = [];

  static create(input: any): ApplicantFormModel {
    return ObjectMapper.deserialize<ApplicantFormModel>(
      ApplicantFormModel,
      input
    );
  }

  public formatDates() {
      if (!isValidDate(this.birthDate)) {
        const birthDateValid = moment(this.birthDate).format('DD/MM/YYYY').toString();
        this.birthDate = birthDateValid != 'Invalid date' ? birthDateValid : this.birthDate;
      }

      if (!isValidDate(this.rgDate)) {
        const rgDateValid = moment(this.rgDate).format('DD/MM/YYYY').toString();
        this.rgDate = rgDateValid != 'Invalid date' ? rgDateValid : this.rgDate;
      }

      if (!isValidDate(this.ctpsIssueDate)) {
        const ctpsDateValid = moment(this.ctpsIssueDate).format('DD/MM/YYYY').toString();
        this.ctpsIssueDate = ctpsDateValid != 'Invalid date' ? ctpsDateValid : this.ctpsIssueDate;
      }
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

import { ObjectMapper } from 'json-object-mapper';
import moment from 'moment';
import { isValidDate } from 'utils/helps';
import { IModel } from '../IModel';
import { Model } from '../model';

export class ApplicantDependentModel
  extends Model
  implements IModel<ApplicantDependentModel>
{
  name: string = '';
  cpf: string = '';
  sex: string = '';
  birthDate: string = '';
  kinshipDegree: 'C' | 'F' | 'E' | 'P' | 'O' = 'F';
  applicantId: string | undefined = undefined;

  static create(input: any): ApplicantDependentModel {
    return ObjectMapper.deserialize<ApplicantDependentModel>(
      ApplicantDependentModel,
      input
    );
  }

  public formatDates() {

    if (!isValidDate(this.birthDate)) {
      const birthDateValid = moment(this.birthDate).format('DD/MM/YYYY').toString();
      this.birthDate = birthDateValid != 'Invalid date' ? birthDateValid : this.birthDate;
    }
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

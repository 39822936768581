import { Injectable } from "@angular/core";
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { HttpService } from "services/http.service";
import { QueryParams } from "services/models/odata/queryParams";
import { Response } from "services/models/response";
import { ReferencePeriodVacationModel } from "services/models/corporate/referencePeriodVacation.model";
import { ReferencePeriodVacationScheduleModel } from "services/models/corporate/referencePeriodVacationSchedule.model";
import { ServiceBase } from "services/servicebase";
import { ReferencePeriodSummaryModel } from "services/models/corporate/referencePeriodSummary.model";
import { map } from "rxjs/operators";
import { ReferencePeriodVacationManagerModel } from "services/models/corporate/referencePeriodVacationManager.model";
import { VacationOptionsModel } from "services/models/corporate/vacationOptions..model";
import { DomainModel, vacationPermissionType } from "services/models/domain.model";
import { DomainService } from "services/domain.service";
import { pageClaims } from "app/page-access";
import { AuthService } from "services/auth.service";

@Injectable()
export class VacationScheduleService extends ServiceBase<ReferencePeriodVacationScheduleModel> {
  constructor(http: HttpService, private _domainService: DomainService, private _authService: AuthService) {
    super("VacationSchedule", http, environment.apiUrl)

    this.domainObj = this._domainService.getDomain();
  }

  createEntity(input: any): ReferencePeriodVacationScheduleModel {
    return ReferencePeriodVacationScheduleModel.create(input);
  }

  removeSchedule(schedule: ReferencePeriodVacationScheduleModel): Observable<any> {
    return this.http.remove(`${this.endpoint}/${schedule.id}`);
  }

  listReferencePeriodsByEmployee(
    params: QueryParams = null,
    employeeId: string
  ): Observable<Response<ReferencePeriodVacationModel[]>> {
    return this.customList<ReferencePeriodVacationModel>(
      params, `reference-period/${employeeId}`, ReferencePeriodVacationModel
    );
  }

  changeVacationScheduleStatus(schedule: ReferencePeriodVacationScheduleModel) {
    return this.http.post(`${this.endpoint}/change-status`, schedule);
  }

  listReferencePeriodsByHierarchy(
    params: QueryParams = null
  ): Observable<Response<ReferencePeriodVacationManagerModel[]>> {
    return this.customList<ReferencePeriodVacationManagerModel>(
      params, `list-reference-periods-by-hierarchy`, ReferencePeriodVacationManagerModel, true
    );
  }

  listReferencePeriodsInVacationByHierarchy(
    params: QueryParams = null
  ): Observable<Response<ReferencePeriodVacationManagerModel[]>> {
    return this.customList<ReferencePeriodVacationManagerModel>(
      params, `list-reference-periods-in-vacation-by-hierarchy`, ReferencePeriodVacationManagerModel, true
    );
  }

  listReferencePeriodsWithPendingSchedules(
    params: QueryParams = null
  ): Observable<Response<ReferencePeriodVacationManagerModel[]>> {
    return this.customList<ReferencePeriodVacationManagerModel>(
      params, `list-reference-periods-with-pending-schedules-by-hierarchy`, ReferencePeriodVacationManagerModel, true
    );
  }

  getSummary(params: QueryParams = null): Observable<Response<ReferencePeriodSummaryModel>> {
    const query = params ? this.createBuildQuery(params) : '';

    return this.http
      .get(`${this.endpoint}/summary${query}`, undefined, undefined, undefined, true)
      .pipe(
        map((res) => {
          const response = new Response<ReferencePeriodSummaryModel>();
          response.data = res.body;
          return response;
        })
      );
  }

  getReferencePeriodsAvailableOptions(type: number, params: QueryParams = null): Observable<Response<VacationOptionsModel>> {
    const query = params ? this.createBuildQuery(params) : '';

    return this.http
      .get(`${this.endpoint}/reference-periods-available-options/${type}${query}`, undefined, undefined, undefined, true)
      .pipe(
        map((res) => {
          const response = new Response<VacationOptionsModel>();
          response.data = res.body;
          return response;
        })
      );
  }

  private checkVacationPermission(vacationPermissionType: vacationPermissionType): boolean {
    const vacationConfig = this.domainObj.vacationManagementParameters;
    if (!!vacationConfig && vacationConfig.controlAccessByHierarchyLevel) {
      const permissionLevels = vacationConfig.hierarchyLevelAccess[vacationPermissionType];
      const userLevels = this._authService.getUserLevels();

      return !!permissionLevels && !!userLevels && permissionLevels.some(s => userLevels.includes(s));
    }

    return false;
  }

  get hasAllowancePermission() {
    return this.checkVacationPermission('programmingWithAllowance');
  }

  get hasWithoutAllowancePermission() {
    return this.checkVacationPermission('programmingWithoutAllowance');
  }

  get hasRequestForSubordinatePermission() {
    return this.checkVacationPermission('requestForSubordinate');
  }

  get hasRequestProgrammingPermission() {
    return this.checkVacationPermission('requestProgramming');
  }

  get isManager() {
    return this._authService.userContainsRoles(...pageClaims.vacation.manager);
  }

  get isVacationManager() {
    return this._authService.userContainsRoles(...pageClaims.vacation.vacationManager);
  }

  get isOnlyManager() {
    return !this.isManager && !this.isVacationManager && this._authService.userContainsRoles(...pageClaims.vacation.onlyManager);
  }
}

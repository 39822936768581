<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; {{ year }}
    <a class="ml-25" href="http://www.adapteti.com.br/" target="_blank">ADAPTE TI</a>
    <span class="d-none d-sm-inline-block">, All rights Reserved</span>
  </span>

  <span class="float-md-right d-block d-md-inline-block mt-25">
    <button class="btn btn-link" (click)="openLgpdModal(1)">Aviso de Privacidade</button>
    <button class="btn btn-link" (click)="openLgpdModal(2)">Termos e Condições</button>
  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

<!-- Buynow Button-->
<div class="buy-now">
  <a href="https://1.envato.market/vuexy_admin" target="_blank" class="btn btn-danger" *ngIf="coreConfig.layout.buyNow"
    >Buy Now</a
  >
</div>
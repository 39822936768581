<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" (click)="redirectToHonme()">
        <span class="brand-logo">
          <img
            src="{{ coreConfig.app.appLogoImage }}"
            alt="brand-logo"
            width="36"
          />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>
<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->
  </div>

  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- Notification -->
    <app-navbar-notification></app-navbar-notification>
    <!--/ Notification -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name font-weight-bolder">{{ Name }}</span
          ><span class="user-status">{{ Company }}</span>
        </div>

        <span *ngIf="!shortProfileImagePath" class="avatar">
          <i data-feather="user" class="font-large-1"> </i>
        </span>

        <span *ngIf="shortProfileImagePath" class="avatar ng-star-inserted">
          <img
            alt="avatar"
            height="40"
            width="40"
            class="round avatar-img"
            [src]="shortProfileImagePath"
          />
        </span>
      </a>

      <div
        ngbDropdownMenu
        aria-labelledby="navbarUserDropdown"
        class="dropdown-menu dropdown-menu-right"
      >
        <a
          *ngIf="currentUser && isEmployeeUserType"
          ngbDropdownItem
          (click)="redirectToProfile()"
        >
          <span data-feather="user"></span>
          Perfil de Colaborador
        </a>
        <a *ngIf="currentUser" ngbDropdownItem (click)="logout()">
          <span data-feather="power"></span>
          Logout
        </a>

        <a
          *ngIf="!currentUser"
          ngbDropdownItem
          [routerLink]="['/', domain?.domainName, 'auth', 'login']"
        >
          <span data-feather="log-in"></span>
          Login
        </a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>

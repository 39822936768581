import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";

export class HiringEventsModel
  extends Model
  implements IModel<HiringEventsModel>
{
  eventDate: string = '';
  applicantId: string = '';
  status: string = '';
  id: string = '';
  enable: boolean = true;
  creationDate: string = '';
  changeDate: string = '';

  static create(input: any): HiringEventsModel {
    return ObjectMapper.deserialize<HiringEventsModel>(
      HiringEventsModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}
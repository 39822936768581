import { ObjectMapper } from "json-object-mapper";
import { Model } from "../model";
import { IModel } from "../IModel";
import { BenefitType, BenefitTypeEnum } from "services/enums/benefitsType.enum";
import { FileContentModel } from "../fileContent";

export class BenefitModel extends Model implements IModel<BenefitModel> {
  companyId: string | undefined = undefined;
  type: BenefitType = "FoodVoucher";
  provider: string = "";
  businessName: string = "";
  cnpj: string = "";
  zipCode: string = "";
  district: string = "";
  address: string = "";
  number: string = "";
  state: string = "";
  city: string = "";
  addressDetails: string = "";
  detailsFile: string = "";
  detailsFileName: string = "";
  detailsFileContent: FileContentModel = new FileContentModel();
  logoFile: string = "";
  logoFileName: string = "";
  logoFileContent: FileContentModel = new FileContentModel();
  cellphone: string = "";
  email: string = "";
  forDependents: boolean | undefined = undefined;
  documentMockupId: string = "";

  static create(input: any): BenefitModel {
    return ObjectMapper.deserialize<BenefitModel>(BenefitModel, input);
  }

  get typeLabel(): string {
    return BenefitTypeEnum.getBy(this.type).label;
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

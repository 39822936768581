import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { JobTitleDocumentModel } from "../models/hiring/jobTitleDocument.model";

import { FileService } from "@core/services/file.service";

import { map } from "rxjs/operators";
import { ApplicantDependentModel } from "services/models/hiring/applicantDependent.model";
import { ApplicantDocumentModel } from "services/models/hiring/applicantDocument.model";
import { HiringEventsModel } from "services/models/hiring/hiring-events.model";
import { Filter } from "services/models/odata/filter";
import { QueryParams } from "services/models/odata/queryParams";
import { environment } from "../../../environments/environment";
import { HttpService } from "../http.service";
import { ApplicantModel } from "../models/hiring/applicant.model";
import { ApplicantFormModel } from "../models/hiring/applicantForm.model";
import { Response } from "../models/response";
import { ServiceBase } from "../servicebase";
import { ReportHiringIndiceModel } from "services/models/hiring/reportHiringIndices.model";
import { HiringStatusType } from "services/enums/HiringStatusType.enum";

@Injectable()
export class ApplicantService extends ServiceBase<ApplicantModel> {
  constructor(http: HttpService, private file: FileService) {
    super("applicant", http, environment.apiUrl);
  }

  createEntity(input: any): ApplicantModel {
    return ApplicantModel.create(input);
  }

  getFormDocuments(
    cpf: string,
    ignoreStatus = false
  ): Observable<Response<JobTitleDocumentModel[]>> {
    return this.http
      .get(`${this.endpoint}/form-documents/${cpf}/${ignoreStatus}`)
      .pipe(
        map((res) => {
          const response = new Response<JobTitleDocumentModel[]>();
          response.data = res.body.map(
            (pp) => JobTitleDocumentModel.create(pp) as JobTitleDocumentModel
          );
          response.count = res.headers.get("x-count");
          return response;
        })
      );
  }

  getApplicantFormById(id: string): Observable<ApplicantFormModel> {
    return new Observable<ApplicantFormModel>((observer) => {
      this.http.get(`${this.endpoint}/${id}`).subscribe(
        (res) => {
          observer.next(ApplicantFormModel.create(res.body));
          observer.complete();
        },
        (err) => observer.error(err)
      );
    });
  }

  getDependentsByApplicantId(
    id: string
  ): Observable<Response<ApplicantDependentModel[]>> {
    return this.http.get(`${this.endpoint}/applicant-dependents/${id}`).pipe(
      map((res) => {
        const response = new Response<ApplicantDependentModel[]>();
        if (res.body)
          response.data = res.body.map(
            (pp) =>
              ApplicantDependentModel.create(pp) as ApplicantDependentModel
          );
        response.count = res.headers.get("x-count");
        return response;
      })
    );
  }

  getByCPF(cpf: string): Observable<ApplicantFormModel> {
    return new Observable<ApplicantFormModel>((observer) => {
      this.http.get(`${this.endpoint}/bycpf-to-fill/${cpf}`).subscribe(
        (res) => {
          observer.next(ApplicantFormModel.create(res.body));
          observer.complete();
        },
        (err) => observer.error(err)
      );
    });
  }

  fillAdmissionForm(form: ApplicantFormModel): Observable<any> {
    return new Observable<any>((observer) => {
      this.http.post(`${this.endpoint}/fill-admission-form`, form).subscribe(
        (res) => {
          observer.next(res);
          observer.complete();
        },
        (err) => observer.error(err)
      );
    });
  }

  fillAdmissionDocuments(docs: any): Observable<any> {
    return new Observable<any>((observer) => {
      this.http
        .post(`${this.endpoint}/fill-admission-documents`, docs)
        .subscribe((res) => {
          observer.next(res);
          observer.complete();
        });
    });
  }

  addAdmissionDocuments(docs: any): Observable<any> {
    return new Observable<any>((observer) => {
      this.http
        .post(
          `${this.endpoint}/add-admission-documents`,
          docs,
          null,
          null,
          true
        )
        .subscribe((res) => {
          observer.next(res);
          observer.complete();
        });
    });
  }

  fiilWorksafety(form: any): Observable<any> {
    return new Observable<any>((observer) => {
      this.http
        .post(`${this.endpoint}/fill-worksafety`, form)
        .subscribe((res) => {
          observer.next(res);
          observer.complete();
        });
    });
  }

  listByStatus(status: HiringStatusType): Observable<ApplicantModel[]> {
    console.log('listByStatus', status);
    return new Observable<ApplicantModel[]>((observer) => {
      this.list({
        select: ["name", "id"],
        orderBy: "name",
        orderByOrder: "asc",
        filter: Filter.equal("hiringStatus", status.id),
      } as QueryParams).subscribe((res) => {
        observer.next(res.data);
        observer.complete();
      });
    });
  }

  getDocuments(id: string): Observable<Response<ApplicantDocumentModel[]>> {
    return this.http.get(`${this.endpoint}/documents/${id}`).pipe(
      map((res) => {
        const response = new Response<ApplicantDocumentModel[]>();
        response.data = res.body.map(
          (pp) => ApplicantDocumentModel.create(pp) as ApplicantDocumentModel
        );
        response.count = res.headers.get("x-count");
        return response;
      })
    );
  }

  getInvalidDocuments(
    id: string
  ): Observable<Response<ApplicantDocumentModel[]>> {
    return this.http.get(`${this.endpoint}/invalid-documents/${id}`).pipe(
      map((res) => {
        const response = new Response<ApplicantDocumentModel[]>();
        response.data = res.body.map(
          (pp) => ApplicantDocumentModel.create(pp) as ApplicantDocumentModel
        );
        response.count = res.headers.get("x-count");
        return response;
      })
    );
  }

  changeApplicantStatus(id: string, status: string): Observable<any> {
    return new Observable<any>((observer) => {
      this.http
        .post(`${this.endpoint}/applicant-status/${id}/${status}`, "")
        .subscribe((res) => {
          observer.next(res);
          observer.complete();
        });
    });
  }

  resendApplicantLink(id: string): Observable<any> {
    return new Observable<any>((observer) => {
      this.http
        .post(`${this.endpoint}/resendLink/${id}`, "")
        .subscribe((res) => {
          observer.next(res);
          observer.complete();
        });
    });
  }

  getHiringEvents(id: string): Observable<HiringEventsModel[]> {
    return new Observable<HiringEventsModel[]>((observer) => {
      this.http.get(`${this.endpoint}/hiring-events/${id}`).subscribe(
        (res) => {
          observer.next(res.body.map((x) => HiringEventsModel.create(x)));
          observer.complete();
        },
        (err) => observer.error(err)
      );
    });
  }

  getReportHiringIndices(begin: string, end: string, companyIds: string[]): Observable<ReportHiringIndiceModel> {
    const searchParams = new URLSearchParams();
    companyIds.forEach((companyIds: string) => searchParams.append("companyIds", companyIds));

    const params = { select: [], filter: `&${searchParams.toString()}` } as QueryParams;
    const query = this.createBuildQuery(params);

    return new Observable<ReportHiringIndiceModel>((observer) => {
      this.http.get(`${this.endpoint}/report-hiring-indices/${begin}/${end}${query}`).subscribe(
        (res) => {
          observer.next(ReportHiringIndiceModel.create(res.body));
          observer.complete();
        },
        (err) => observer.error(err)
      );
    });
  }

  translateHiringStatus(status: string): string {
    switch (status) {
      case "WaitingSesmt":
        return "Aguardando Sesmt";
      case "WaitingApplicant":
        return "Aguardando Candidato";
      case "WaitingDP":
        return "Aguardando DP";
      case "InvalidDocs":
        return "Reenvio de documentos";
      case "Finished":
        return "Finalizado";
      case "Integrated":
        return "Integrado";
      case "Expired":
        return "Expirado";
    }
  }

}

import "@angular/common/locales/global/pt";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { appInjector } from "@core/injector/app-injector.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmDialogComponent } from "app/layout/components/confirm-dialog/confirm-dialog.component";
import f from "odata-filter-builder";
import buildQuery from "odata-query";
import { AuthService } from "services/auth.service";
import { CustomAction, ShowWhen } from "services/models/customAction";
import { Filter, FilterType } from "services/models/odata/filter";
import { OdataEntityField } from "services/models/odata/odataEntityField";
import { QueryParams } from "services/models/odata/queryParams";
import { isBoolean, isNullOrEmpty, isNumber } from "utils/helps";
import { TableHeaderFilterComponentService } from "../table/table-header-filter/table-header-filter.service";
import { ToastService } from "@core/components/toasts/toasts.service";
import moment from "moment-business-days";

@Component({
  selector: "app-table-list-abstract",
  templateUrl: "./table-list-abstract.component.html",
  styleUrls: ["./table-list-abstract.component.scss"],
})
export class TableListAbstractComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean = false;
  @Input() items: any[] = [];
  @Input() fields: string[] = [];
  @Input() columns: OdataEntityField[] = [];
  @Input() exclude = ["id"];
  @Input() service: any;
  @Input() queryParams: QueryParams = {} as QueryParams;
  @Input() count = 0;
  @Input() searchable = true;
  @Input() menuActions = true;
  @Input() customActions?: CustomAction[];
  @Input() canEdit = true;
  @Input() canDelete = false;
  @Input() canCreate = false;
  @Input() emitQueryMethod?= false;
  @Input() searchValue = "";
  @Output() queryMethod: EventEmitter<any> = new EventEmitter();
  QueryParams = {} as QueryParams;
  public page = 1;
  _modalService;
  public combinedFilter: QueryParams = {} as QueryParams;
  public multipleFilters: {
    value: any;
    property: string;
    filter: FilterType;
  }[] = [];
  @ViewChild("th[header-filter]", { static: true })
  thFilters: QueryList<ElementRef>;

  constructor(
    private authService: AuthService,
    private router: Router,
    private _toastr: ToastService,
    private _thService: TableHeaderFilterComponentService
  ) { }

  ngOnDestroy(): void {
    console.log("Method not implemented.");
  }

  ngOnInit(): void {
    const currentpath = this.router.url.split("/").filter((i) => i);
    this._modalService = appInjector.get(NgbModal);
    // const page = currentpath[currentpath.length - 1];
    // this.canEdit = this.authService.checkPermissionPage(page, "edit");
    // this.canDelete = this.authService.checkPermissionPage(page, "delet");

    if (this.viewColumns.filter((x) => x.dafaultFilterValue).length > 1)
      throw new Error("only one 'dafaultFilterValue' is allowed");
  }

  hideCustomActionItem(hide: boolean, showWhen: ShowWhen, item: any) {
    return !hide ? this.validateShowWhen(showWhen, item) : false;
  }

  validateShowWhen(showWhen: ShowWhen, item: any): boolean {
    return showWhen ? this.checkShowWhen(showWhen, item) : true;
  }

  checkShowWhen(hide: ShowWhen, item: any) {
    const hideValue = hide.value.toString();
    const itemValue = item[hide.property].toString();

    if (Array.isArray(hide.value)) {
      return hideValue.includes(itemValue);
    }

    return itemValue === hideValue ? true : false;
  }

  setOrder(field: string) {
    if (!this.fields.includes(field as never)) {
      return true;
    }

    this.queryParams.orderBy = field;
    this.query();

    if (this.queryParams.orderByOrder === "asc") {
      this.queryParams.orderByOrder = "desc";
    } else {
      this.queryParams.orderByOrder = "asc";
    }
    return false;
  }

  private preservFilters() {
    if (!this.queryParams.filter && this.queryParams.currentFilter)
      this.queryParams.filter = this.queryParams.currentFilter;
    this.queryParams.filter =
      this.combinedFilter.filter === "" ||
        this.combinedFilter.filter === undefined
        ? this.queryParams.filter
        : this.combinedFilter.filter;
  }
  query() {
    this.preservFilters();
    this.emitQueryMethod ? this.queryMethod.emit() : this.executeQuery();
  }

  executeQuery() {
    const fields = this.getFields();
    this.queryParams.select =
      fields?.length > this.queryParams.select.length
        ? fields
        : this.queryParams.select;
    this.service.list(this.queryParams).subscribe(({ data, count }) => {
      this.items = data;
      this.count = count;
    });

    this.searchValue = this.queryParams.filter;
    this.queryParams.filter = "";
  }

  changeStatus({ id = false }) {
    if (id) {
      this.service.changeStatus(id).subscribe(() => this.query());
    }
  }

  delete(id: string) {
    if (id) {
      const modalRef = this._modalService.open(ConfirmDialogComponent);
      modalRef.componentInstance.message =
        "Deseja realmente excluir o registro?";
      modalRef.componentInstance.focusConfirm = true;
      modalRef.result.then((confirmed) => {
        if (confirmed) {
          this.queryParams = new QueryParams();
          this.service.delete(id).subscribe(() => this.query());
        }
      });
    }
  }

  getFields() {
    let allKeys: any = [];
    if (this.items?.length) {
      let keys = Object.keys(this.items[0]);
      keys = keys.filter((key) => !this.exclude.includes(key));
      if (this.fields.length) {
        keys = keys.filter((key) => this.fields.includes(key as never));
      }
      allKeys = this.fields.filter((field) => keys.includes(field));
    }
    return allKeys;
  }

  get viewColumns(): OdataEntityField[] {
    return this.columns.filter((x) => !this.exclude.includes(x.field));
  }

  isIncludeInViewColumns(name: string): boolean {
    return this.viewColumns.map((f) => f.field).includes(name);
  }

  getViewColumnByName(name: string) {
    return this.viewColumns.find((v) => v.field === name);
  }

  getColumByName(name: string): OdataEntityField {
    return this.columns.filter((x) => x.field === name)[0];
  }

  handleText(inputName, item = null) {
    const input = item[inputName];
    const text = input?.nome || input;
    const column = this.getColumByName(inputName);
    if (column.replaceField) {
      return item[column.replaceField];
    }
    if (column.renderField) {
      return item[column.renderField];
    }
    if (text === false) {
      return "Não";
    } else if (text === true) {
      return "Sim";
    }
    return text;
  }

  checkAll(event) {
    this.items.forEach((item) => (item.checked = event.target.checked));
  }

  filterByProperty(
    field: OdataEntityField,
    event: any,
    filterType: FilterType = FilterType.CONTAINS
  ) {
    if (isNullOrEmpty(event)) {
      this.removeMultipleFilters(field.field);
      this.queryParams.currentFilter = '';
      this.query();
    } else {
      const property = field.field;
      let value = field.filterPipe ? field.filterPipe(event) : event;

      const isNumb = isNumber(value);
      const isBool = isBoolean(value);
      const dates = this.filterByDate(value.toString());
      if (dates !== false) {
        this.queryParams.filter = Filter.dataRange(
          property,
          dates[0],
          dates[1]
        );
      } else {
        switch (filterType) {
          case FilterType.GREATEROREQUALTHAN:
            this.queryParams.filter = Filter.greaterOrEqualThan(
              property,
              JSON.parse(value)
            );
            break;
          case FilterType.CONTAINS:
            value = `${value}`;
            this.queryParams.filter = Filter.contains(property, value);
            break;
          case FilterType.DATE:
            if (this.isValidDateFormat(value)) {
              this.queryParams.filter = Filter.filterByExactDate(property, value);
            } else {
                this._toastr.showWarning('Data inválida. Formato esperado: dd/mm/aaaa');
                return;
            }
            break;
          case FilterType.EQUALS:
            if (isBool || isNumb) {
              value = JSON.parse(value);
              this.queryParams.filter = Filter.equal(property, value);
            } else {
              value = `${value.toString().trim()}`;
              this.queryParams.filter = Filter.equal(property, value);
            }
            break;
          default:
            break;
        }
      }
      // this.filteredField = property;

      this.addMultipleFilters(value, property, filterType);
      if (this.multipleFilters.length > 1) {
        this.createMoreFilters();
      }
      this.query();
    }
  }

  filterByDate(dataString: string) {
    let date = [];
    if (dataString.includes("/")) {
      date = dataString.split("/");
      if (date.length !== 3) {
        return false;
      }
    } else {
      return false;
    }

    const day = parseInt(date[0], 10);
    const month = parseInt(date[1], 10) - 1;
    const year = parseInt(date[2], 10);

    const dateBegin = new Date(year, month, day, 0, 0, 0, 0);
    const dateEnd = new Date(year, month, day, 23, 59, 59, 999);
    return [dateBegin, dateEnd];
  }

  getTitle(name: string, item: string) {
    const fileds = this.viewColumns.filter(
      (f) => f.isTitle && f.field === name
    );
    if (fileds.length > 0) {
      return item[name];
    }
    return "";
  }

  addMultipleFilters(
    value: any,
    property: string,
    filterType: FilterType
  ): void {
    this.multipleFilters = this.multipleFilters.filter(
      (x) => x.property !== property
    );
    this.multipleFilters.push({
      value: value,
      property: property,
      filter: filterType,
    });
  }

  removeMultipleFilters(property: string) {
    this.multipleFilters = this.multipleFilters.filter(
      (x) => x.property !== property
    );

    if (this.multipleFilters.length >= 1) {
      this.createMoreFilters();
      this.queryParams.filter = this.combinedFilter.filter;
      this.combinedFilter.filter = "";
    }
  }

  createMoreFilters() {
    let filter: string[] = [];

    this.multipleFilters.forEach((element) => {
      const isNumb = isNumber(element.value);
      const isBool = isBoolean(element.value);

      switch (element.filter) {
        case FilterType.CONTAINS:
          filter.unshift(
            f()
              .contains(
                (x) => x.toLower(element.property),
                element.value.toLocaleLowerCase()
              )
              .toString()
          );
          break;
        case FilterType.EQUALS:
          if (isBool || isNumb) {
            filter.unshift(
              f().eq(element.property.toLowerCase(), element.value).toString()
            );
          } else {
            filter.unshift(
              f().eq(element.property.toLowerCase(), element.value).toString()
            );
          }
          break;
        default:
          break;
      }
    });

    this.combinedFilter.filter = buildQuery({ filter }).replace("?", "&");
  }

  isFiltred(field): boolean {
    return !isNullOrEmpty(
      this.multipleFilters.find((f) => f.property === field)
    );
  }

  removeFilterBtnHandler(filter) {
    this.queryParams.currentFilter = '';
    this.filterByProperty(
      this.getViewColumnByName(filter.property),
      null,
      filter.filter
    );
    this._thService.emit(filter.property);
  }

  isValidDateFormat(dateString: string): boolean {
    const date = moment(dateString, "DD/MM/YYYY", true);

    return date.isValid();
  }

}

import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import f from "odata-filter-builder";
import buildQuery from "odata-query";
import { Observable } from "rxjs";
import { Filter } from "services/models/odata/filter";
import { QueryParams } from "../models/odata/queryParams";
import { ServiceBase } from "services/servicebase";
import { HttpService } from "../http.service";
import { EmployeeModel } from "../models/employee.model";
import { Response } from "../models/response";
import { PermissionModel } from "../models/permission.model";
import { map } from "rxjs/operators";
import { TimeShiftHistoryModel } from "../models/corporate/timeShiftHistory.model";
import { FileModel } from "../models/file.model";

@Injectable()
export class EmployeeService extends ServiceBase<EmployeeModel> {
  constructor(http: HttpService) {
    super("employee", http, environment.apiUrl);
  }

  createEntity(input: any): EmployeeModel {
    return EmployeeModel.create(input);
  }

  loadEmployeesByCompany(companyId: string): Observable<EmployeeModel[]> {
    return new Observable<any>((observer) => {
      this.list({
        select: ["name", "cpf", "companyId", "enable", "registration"],
        orderBy: "name",
        orderByOrder: "asc",
        filter: Filter.equalToGuid("companyId", companyId),
      } as QueryParams).subscribe((res) => {
        observer.next(res.data);
      }),
        (err) => {
          observer.error(err);
        };
    });
  }

  loadEmployeesByNameAndOptionalCompany(
    search: string,
    companyId: string = null
  ): Observable<EmployeeModel[]> {
    const filter = companyId
      ? f()
          .and(f().eq("companyId", companyId).toString().replace(/'/g, ""))
          .contains("name", search)
          .or(f().contains("registration", search))
          .toString()
      : f()
          .contains("name", search)
          .or(f().contains("registration", search))
          .toString();

    return new Observable<any>((observer) => {
      this.list({
        select: ["name", "cpf", "companyId", "registration", "enable"],
        orderBy: "name",
        orderByOrder: "asc",
        filter: buildQuery({ filter }).replace("?", "&"),
      } as QueryParams).subscribe((res) => {
        observer.next(res.data);
      }),
        (err) => {
          observer.error(err);
        };
    });
  }

  loadEmployeesByNameAndOthers(
    name: string,
    companyId: string = null,
    costCenterId: any = null,
    timeShiftId: any = null,
    jobtitleId: any = null
  ): Observable<EmployeeModel[]> {
    const nameFilter = f().contains("name", name).toString();
    const registrationFilter = f()
      .contains("registration", name.toUpperCase())
      .toString();

    const combinedFilter = `(${nameFilter.toString()} or ${registrationFilter.toString()})`;

    const filter = [combinedFilter];

    if (companyId !== undefined && companyId !== null) {
      filter.unshift(Filter.buildFilter("companyId", companyId));
    }

    if (costCenterId !== undefined && costCenterId !== null && costCenterId.length > 0) {
      filter.unshift(Filter.inGuid("costCenterId", costCenterId));
    }

    if (timeShiftId !== undefined && timeShiftId !== null && timeShiftId.length > 0) {
      filter.unshift(Filter.inGuid("timeShiftId", timeShiftId));
    }

    if (jobtitleId !== undefined && jobtitleId !== null && jobtitleId.length > 0) {
      filter.unshift(Filter.inGuid("jobtitleId", jobtitleId));
    }

    return new Observable<any>((observer) => {
      this.list({
        select: ["name", "cpf", "companyId", "costCenterId", "timeShiftId", 
          "jobTitleId", "registration", "enable", "type"],
        orderBy: "name",
        orderByOrder: "asc",
        filter: buildQuery({ filter }).replace("?", "&"),
      } as QueryParams, true).subscribe((res) => {
        observer.next(res.data.filter((e) => e.enable));
      }),
        (err) => {
          observer.error(err);
        };
    });
  }

  changePermissions(
    employeeId: string,
    permissions: PermissionModel[]
  ): Observable<any> {
    return this.http.put(`employee/permissions/${employeeId}`, permissions);
  }

  listEmployeePermissions(
    employeeId: string
  ): Observable<Response<PermissionModel[]>> {
    return this.http
      .get(`employee/list-employee-permissions/${employeeId}`)
      .pipe(
        map((res: any) => {
          const response = new Response<PermissionModel[]>();
          response.data = res.body.map((e: PermissionModel) =>
            PermissionModel.create(e)
          );
          return response;
        })
      );
  }

  changeTimeShift(transfer: TimeShiftHistoryModel) {
    return this.http.post(
      `${this.endpoint}/change.timeshift`,
      transfer,
      null,
      null,
      true
    );
  }

  uploadProfileImage(employeeId: string, model: FileModel) {
    return this.http.post(
      `${this.endpoint}/upload/profile-image/${employeeId}`,
      model
    );
  }
}

import { DocumentTypeModel } from './document-type.model';
import { ObjectMapper } from 'json-object-mapper';
import { IModel } from './../IModel';
import { Model } from './../model';

export class JobTitleDocumentModel
  extends Model
  implements IModel<JobTitleDocumentModel>
{
  jobTitleId: string | undefined = undefined;
  documentTypeId: string | undefined = undefined;
  required: boolean = true;
  forDependents: boolean = false;
  sideType: number = 1 | 2 | 3;
  twoSides: boolean = false;
  order: number = 99;
  documentType: DocumentTypeModel = new DocumentTypeModel();

  static create(input: any): JobTitleDocumentModel {
    return ObjectMapper.deserialize<JobTitleDocumentModel>(
      JobTitleDocumentModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model, OptionModel } from "../model";

export class DocumentMockupVariableModel
  extends Model
  implements IModel<DocumentMockupVariableModel> {

  id: string = "";
  key: string = "";
  title: string = "";
  source: number = 0;
  sample: string = "";
  enable: boolean = false;
  description: string = "";
  table: string = "";
  creationDate: string = "";
  column: string = "";
  changeDate: string = "";

  public toOption(): OptionModel {
    return {
      label: this.title,
      value: this.id,
    } as OptionModel;
  }

  static create(input): DocumentMockupVariableModel {
    return ObjectMapper.deserialize<DocumentMockupVariableModel>(
      DocumentMockupVariableModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

import * as moment from 'moment';
import { Filter } from 'services/models/odata/filter';
import * as momentBusinessDays from 'moment-business-days';

export const removeAcentos = (palavra: string): string => palavra.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const isEmptyObject = (object) => {
  if (object) { return Object.keys(object).length === 0 && object.constructor === Object; }
  return true;
};

export const convertToUpperCase = (object: any) => {
  Object.keys(object).forEach((e) => {
    let isString: boolean;
    if (object[e] && e != 'birthplace' &&
      e != 'domain' && e != 'hiringStatus') { isString = object[e].constructor.name == 'String'; }
    if (isString) { object[e] = object[e].toUpperCase(); }
  });
};

const stringIsNumber = value => isNaN(Number(value)) === false;

// Turn enum into array
export const enumToArray = (enumme) =>
  Object.keys(enumme)
    .filter(stringIsNumber)
    .map(key => enumme[key]);

export const capitalizeString = (value: string) => value[0].toUpperCase() + value.slice(1);

export const isValidDateString = (field = 'data'): boolean => {
  return moment(new Date(field)).isValid()
}

export const isValidDateValue = (field = 'data'): boolean => {
  const isValidFormat = isValidDateString(field);

  if (!isValidFormat)
    return false;

  if (moment(new Date(field)).get('year') < 1990)
    return false;

  return true;
}

export const toDayFilter = (field = 'data'): string => {

  const dataInicio = moment().startOf('date').format('YYYY-MM-DD');
  const dataFim = moment().endOf('date').format('YYYY-MM-DD');

  const startDate = { type: 'guid', value: dataInicio };
  const endDate = { type: 'guid', value: dataFim };

  const filterQuery = [{ [field]: { ge: startDate, le: endDate } }];
  return Filter.multEquals(filterQuery);
};

export const toDayTransationFilter = (): string => {
  const toDay = moment().format('YYYY/MM/DD');
  return `&inicio=${toDay}&fim=${toDay}`;
};

export const nextBusinessDayByQuantity = (quantity: number = 1): string => {
  const today = moment(Date.now()).format('DD-MM-YYYY');
  let nextDay = moment(today, "DD-MM-YYYY").add(quantity, 'd');
  if (!isBusinessDay(nextDay)) {
    nextDay = nextBusinessDay(nextDay);
  }
  const result = getDayFromObjectMomentBusinessDays(nextDay);
  return result;
}

export const nextBusinessDay = (day: any): any => momentBusinessDays(day, 'DD-MM-YYYY').nextBusinessDay();

export const isBusinessDay = (day: any): boolean => momentBusinessDays(day, 'DD-MM-YYYY').isBusinessDay();

export const getDayFromObjectMomentBusinessDays = (momentBD: any): string => {
  const object = { day: momentBD._d };
  return moment(object.day, 'DD/MM/YYYY').format('YYYY-MM-DD');
}

export default {
  removeAcentos
};

export const omit_special_char = (event) => {
  const k = event.charCode;
  return (
    (k > 64 && k < 91) ||
    (k > 96 && k < 123) ||
    k == 8 ||
    k == 32 ||
    (k >= 48 && k <= 57)
  );
};

export const customPattern = {
  B: {
    pattern: new RegExp('[a-zA-Z]'),
    optional: true,
  },
};

export const customPatternWithNumbers = {
  C: {
    pattern: new RegExp('[a-zA-Z0-9]'),
    optional: true,
  },
};

export const removeAcento = (text: string) => {
  text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
  text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
  text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
  text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
  text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
  text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
  return text;
}

export const comparer = (b1: any, b2: any) => {
  return b1 && b2 ? b1 == b2 : b2 == b2;
};

export const isValidDate = (data: any): boolean => {
  return moment(data, 'DD/MM/YYYY', true).isValid();
};

export const convertToCamelCase = (str: string): string => {
  return str.toLowerCase().replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );
}

export const getHourMinuteTime = (decimalHourTime: number) => {
  const intervalTimeDiffInMinutes = moment().startOf('day').add(decimalHourTime, 'hours').diff(moment().startOf('day'), 'minutes');

  const intervalHours = Math.floor(intervalTimeDiffInMinutes / 60);
  const intervalMinutes = intervalTimeDiffInMinutes % 60;

  return `${intervalHours.toString().padStart(2, '0')}:${intervalMinutes.toString().padStart(2, '0')}`;
}

export const schoolingOptions = [
  { value: 10, label: 'Analfabeto' },
  { value: 20, label: 'Até 4° série incompleta (primário incompleto)' },
  { value: 25, label: '4° série completa do 1° grau (primário completo)' },
  { value: 30, label: 'Primeiro grau (ginásio) incompleto' },
  { value: 35, label: 'Primeiro grau (ginásio) completo' },
  { value: 40, label: 'Segundo grau (colegial) incompleto' },
  { value: 45, label: 'Segundo grau (colegial) completo' },
  { value: 50, label: 'Superior incompleto' },
  { value: 55, label: 'Superior completo' },
  { value: 65, label: 'Mestrado completo' },
  { value: 75, label: 'Doutorado completo' },
  { value: 85, label: 'Pós-graduação / Especialização' },
  { value: 95, label: 'Pós-doutorado' },
];

export const months = [
  { name: 'Janeiro', value: 0 },
  { name: 'Fevereiro', value: 1 },
  { name: 'Março', value: 2 },
  { name: 'Abril', value: 3 },
  { name: 'Maio', value: 4 },
  { name: 'Junho', value: 5 },
  { name: 'Julho', value: 6 },
  { name: 'Agosto', value: 7 },
  { name: 'Setembro', value: 8 },
  { name: 'Outubro', value: 9 },
  { name: 'Novembro', value: 10 },
  { name: 'Dezembro', value: 11 },
];

export const isBoolean = (value) => {
  try {
    return typeof JSON.parse(value) === "boolean";
  } catch (e) {
    return false;
  }
};

export const isNumber = (value) => {
  try {
    return typeof JSON.parse(value) === "number";
  } catch (e) {
    return false;
  }
};

export const isNotNumber = (value) => {
  return (
    value && typeof value === "string" && isNaN(Number(value.replace(",", ".")))
  );
};

export const isNullOrEmpty = (value) => {
  return value === undefined || value == null || value === "";
};

export const getBooleanOptions = () => [{ value: true, label: "Sim" }, { value: false, label: "Não" }];

export const distinct = (values: string[]) => {
  return Array.from(new Set(values));
}

export function toGUID(guidId: string) {
  if (guidId.length !== 32) {
    throw new Error('A string deve ter exatamente 32 caracteres.');
  }
  const parts = [
    guidId.slice(0, 8),
    guidId.slice(8, 12),
    guidId.slice(12, 16),
    guidId.slice(16, 20),
    guidId.slice(20, 32)
  ];
  return parts.join('-');
}

export const guidEmpty = '00000000-0000-0000-0000-000000000000';
import { Injectable } from "@angular/core";
import { CoreMenuItem } from "@core/types";
import { BehaviorSubject, Observable } from "rxjs";
import { map, timeout } from "rxjs/operators";
import { HttpService } from "./http.service";
import { TradeUnionModel } from "./models/corporate/tradeUnion.model";
import { DomainModel } from "./models/domain.model";
import { FeatureFlagModel } from "./models/featureFlag.model";
import { OptionModel } from "./models/model";
import { Response } from "./models/response";
import { ServiceBase } from "./servicebase";
import { HttpHeaders } from "@angular/common/http";

@Injectable()
export class DomainService extends ServiceBase<DomainModel> {
  domainSubject = new BehaviorSubject(null);

  constructor(http: HttpService) {
    super("companygroup", http);
  }

  createEntity(input: any): DomainModel {
    return DomainModel.create(input);
  }

  setDomain(domain: DomainModel) {
    if (domain) {
      this.domainSubject.next(domain);
    }
  }

  getDomain(): DomainModel {
    return this.domainSubject.value;
  }

  getMenu(): CoreMenuItem[] {
    let menus = this.getDomain()?.menu;
    if (menus) {
      const defaultMenu = {
        icon: "feather-home",
        id: "home",
        title: "Inicio",
        type: "item",
        url: `${this.getDomain().domainName}/home`,
      } as CoreMenuItem;
      return [defaultMenu, ...menus];
    }
    return [];
  }

  getAllowedUrls(): string[] {
    const customUrls = ["gruposantana/hiring/benefit"];

    return this.getDomain()?.allowedUrls.concat(customUrls);
  }

  get CompanyImageUrl(): string {
    const domain = this.getDomain();

    if (domain && domain?.image) {
      try {
        return domain.image;
      } catch (_) {
        return `${this.BASE_COMPANIES_IMAGE_URL}/${domain.image}`;
      }
    }

    return null;
  }

  checkDomainName(
    domainName: string,
    noCache: boolean = false
  ): Observable<DomainModel> {
    const options = noCache
      ? {
          headers: new HttpHeaders({
            "Cache-Control": "no-cache",
          }),
        }
      : undefined;
    return this.http
      .get(`${this.endpoint}/check.domainname/${domainName}`, options)
      .pipe(
        map(({ body }) => {
          return DomainModel.create(body);
        })
      );
  }

  checkModule(module: string): boolean {
    const modules = [...this.getMenu().filter((f) => !f.children)];
    this.getMenu().forEach((item) => {
      if (item.children) {
        modules.push(...item.children);
      }
    });
    const isModuleFound = modules.find((f) => f.id === module) !== undefined;

    return isModuleFound;
  }

  getTradeUnionOptions(): Observable<Response<OptionModel[]>> {
    return this.http.get("companygroup/list-trade-unions").pipe(
      map((res: any) => {
        const response = new Response<OptionModel[]>();
        response.data = res.body.map((e: TradeUnionModel) =>
          TradeUnionModel.create(e).toOption()
        );
        return response;
      })
    );
  }

  get featureFlag(): FeatureFlagModel {
    return FeatureFlagModel.create(this.getDomain().featureFlag);
  }
}

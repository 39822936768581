import { ObjectMapper } from 'json-object-mapper';
import { IModel } from './IModel';
import { Model } from './model';

export class IntegratedReportsParametersModel extends Model implements IModel<IntegratedReportsParametersModel> {
  yearStartWorkingHours: number = 2018;
  yearStartPaycheck: number = 2018;
  yearStartVacation: number = 2018;
  yearStartYield: number = 2018;
  dateStartAnnuaLLeave: string = '';

  static create(input: any): IntegratedReportsParametersModel {
    return ObjectMapper.deserialize<IntegratedReportsParametersModel>(IntegratedReportsParametersModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

import { Injectable } from "@angular/core";

import { ServiceBase } from "../servicebase";
import { HttpService } from "../http.service";
import { DocumentTypeModel } from "../models/hiring/document-type.model";
import { Observable } from "rxjs";
import { Response } from "services/models/response";
import { map } from "rxjs/operators";
import { QueryParams } from "services/models/odata/queryParams";
import { StandardDocumentModel } from "services/models/hiring/standardDocument.model";

@Injectable()
export class DocumentTypeService extends ServiceBase<DocumentTypeModel> {
  constructor(http: HttpService) {
    super("documenttype", http);
  }

  createEntity(input: any): DocumentTypeModel {
    return DocumentTypeModel.create(input);
  }

  getAdditionalDocuments(): Observable<Response<DocumentTypeModel[]>> {
    return this.http
    .get(`${this.endpoint}/additional-documents`)
    .pipe(
      map((res) => {
        const response = new Response<DocumentTypeModel[]>();
        response.data = res.body.map((pp) => DocumentTypeModel.create(pp) as DocumentTypeModel);
        response.count = res.headers.get('x-count');
        return response;
      })
    );
  }

  getDefaultDocuments(): Observable<Response<StandardDocumentModel[]>> {
    return this.http
    .get(`${this.endpoint}/default-documents`)
    .pipe(
      map((res) => {
        const response = new Response<StandardDocumentModel[]>();
        response.data = res.body.map((pp) => StandardDocumentModel.create(pp) as StandardDocumentModel);
        response.count = res.headers.get('x-count');
        return response;
      })
    );
  }
}

import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "services/http.service";
import { BenefitModel } from "services/models/corporate/benefit.model";
import { QueryParams } from "services/models/odata/queryParams";
import { ServiceBase } from "services/servicebase";
import { Response } from "services/models/response";
import { PetitionModel } from "services/models/corporate/petitions.model";

@Injectable()
export class BenefitService extends ServiceBase<BenefitModel> {
  constructor(http: HttpService) {
    super("benefit", http, environment.apiUrl);
  }

  createEntity(input: any): BenefitModel {
    return BenefitModel.create(input);
  }

  getDetails(id: string): Observable<any> {
    return this.http.get(`${this.endpoint}/${id}/details`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getLogo(id: string): Observable<any> {
    return this.http.get(`${this.endpoint}/${id}/logo`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getPetitions(params: QueryParams): Observable<Response<PetitionModel[]>> {
    const query = this.createBuildQuery(params);
    return this.http.get(`${this.endpoint}/petitions/odata${query}`).pipe(
      map((res) => {
        const response = new Response<PetitionModel[]>();
        response.data = res.body.map((pp) => PetitionModel.create(pp));
        response.count = res.headers.get("x-count");
        return response;
      })
    );
  }

  getSummary(): Observable<any> {
    return this.http.get(`${this.endpoint}/petitions/summary`);
  }

  approvalPetition(petition: PetitionModel, status: string) {
    return this.http.put(`${this.endpoint}/petitions/approval`, {
      status: status,
      id: petition.id,
    });
  }
}

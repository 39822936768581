import { BaseEnum } from "./baseEnum";

const listPetitionsRequestTypeEnum: Array<PetitionsRequestTypeEnum> = [];

export class PetitionsRequestTypeEnum extends BaseEnum {
  static ACCESSION = new PetitionsRequestTypeEnum(0, "ACCESSION", "Adesão", "success");
  static INCLUDE_DEPENDENTS = new PetitionsRequestTypeEnum(
    1,
    "INCLUDE_DEPENDENTS",
    "Incl. Dependente",
    "success"
  );
  static EXCLUDE_DEPENDENTS = new PetitionsRequestTypeEnum(
    2,
    "EXCLUDE_DEPENDENTS",
    "Excl. Dependente",
    "danger"
  );
  static CANCELLATION_REQUEST = new PetitionsRequestTypeEnum(
    3,
    "CANCELLATION_REQUEST",
    "Cancelamento",
    "danger"
  );

  constructor(
    public id: number,
    public value: string,
    public label: string,
    public className: string
  ) {
    super(id, value, label, className);
    listPetitionsRequestTypeEnum.push(this);
  }

  public static buildListEnum(): Array<BaseEnum> {
    return listPetitionsRequestTypeEnum;
  }
}

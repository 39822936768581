import { BaseEnum } from "./baseEnum"

const listEnum: Array<HiringStatusType> = [];

export class HiringStatusType extends BaseEnum {
  static Expired = new HiringStatusType(0, 'Expired', 'Expirado', 'danger');
  static WaitingSesmt = new HiringStatusType(1, 'WaitingSesmt', 'Aguardando Sesmt', 'secondary');
  static WaitingApplicant = new HiringStatusType(2, 'WaitingApplicant', 'Aguardando Candidato', 'info');
  static WaitingDP = new HiringStatusType(3, 'WaitingDP', 'Aguardando DP', 'warning');
  static InvalidDocs = new HiringStatusType(4, 'InvalidDocs', 'Reenvio de documentos', 'danger');
  static Finished = new HiringStatusType(5, 'Finished', 'Finalizado', 'success');
  static Integrated = new HiringStatusType(6, 'Integrated', 'Integrado', 'primary');

  constructor(public id: number, public value: string, public label: string, public className: string) {
    super(id, value, label, className)
    listEnum.push(this);
  }

  public static buildListEnum(): Array<BaseEnum> {
    return listEnum;
  }
}
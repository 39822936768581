import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[uppercase]'
})
export class UppercaseDirective {
  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('input', ['$event.target.value']) onInput(value) {
    this.control.control.setValue(value.toUpperCase(), { emitEvent: false });
  }
}
import { HiringConfigService } from "./hiring/hiring-config.service";
import { EmployeeService } from "./corporate/employee.service";
import { BatimentoService } from "./reports/batimento.service";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ApplicantService } from "./hiring/applicant.service";
import { AuthService } from "./auth.service";
import { GeographyService } from "./geography.service";
import { CompanyService } from "./corporate/company.service";
import { DocumentTypeService } from "./hiring/document-type.service";
import { DomainService } from "./domain.service";
import { HttpService } from "./http.service";
import { JobTitleService } from "./hiring/jobTitle.service";
import { LaborReportService } from "./reports/labor-report.service";
import { ResetPasswordService } from "./reset-password.service";
import { CostCenterService } from "./corporate/costcenter.service";
import { HandleErrorService } from "./handleError.service";
import { ApplicantDocumentService } from "./hiring/applicant-document.service";
import { PontoService } from "./ponto.service";
import { HierarchyLeaderService } from "./corporate/hierarchyLeader.service";
import { TimeOccurrenceService } from "./corporate/timeOccurrence.service";
import { PointTimePeriodService } from "./corporate/pointTimePeriod.service";
import { HourlyBasisService } from "./corporate/hourlyBasis.service";
import { TimeShiftService } from "./hiring/timeShift.service";
import { TimeShiftHistoryService } from "./corporate/timeShiftHistory.service";
import { PointTimeExceptionService } from "./corporate/pointTimeException.service";
import { InfringementService } from "./corporate/infringement.service";
import { EmployeeManagementService } from "./corporate/employeeManagement.service";
import { VacationScheduleService } from "./corporate/vacationSchedule.service";
import { ExitInterviewService } from "./hiring/exitInterview.service";
import { BenefitService } from "./corporate/benefit.service";
import { DocumentMockupService } from "./documents/documentmockup.service";
import { BenefitsEmployeeService } from "./corporate/benefitsEmployee.service";

@NgModule({
  declarations: [],
  imports: [CommonModule, BrowserAnimationsModule],
  providers: [
    AuthService,
    CompanyService,
    CostCenterService,
    DomainService,
    HiringConfigService,
    HttpService,
    GeographyService,
    PontoService,
    LaborReportService,
    ResetPasswordService,
    ApplicantService,
    ApplicantDocumentService,
    JobTitleService,
    BenefitService,
    BenefitsEmployeeService,
    DocumentMockupService,
    TimeShiftService,
    DocumentTypeService,
    BatimentoService,
    EmployeeService,
    TimeOccurrenceService,
    PointTimePeriodService,
    HourlyBasisService,
    TimeShiftHistoryService,
    InfringementService,
    EmployeeManagementService,
    VacationScheduleService,
    PointTimeExceptionService,
    HandleErrorService,
    HierarchyLeaderService,
    ExitInterviewService,
  ],
})
export class ServicesModule {}

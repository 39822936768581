import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model, OptionModel } from "../model";
import type { DocumentMockupVariableModel } from "./documentmockupVariable.model";

export class DocumentMockupModel
	extends Model
	implements IModel<DocumentMockupModel> {
	companyGroupId: string = "";
	content: string = "";
	title: string = "";
	variables: DocumentMockupVariableModel[] = [];

	public toOption(): OptionModel {
		return {
			label: this.title,
			value: this.id,
		} as OptionModel;
	}

	static create(input): DocumentMockupModel {
		return ObjectMapper.deserialize<DocumentMockupModel>(
			DocumentMockupModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

import { Injectable } from "@angular/core";

import { ServiceBase } from "../servicebase";
import { HttpService } from "../http.service";
import { map } from "rxjs/operators";
import { HiringConfigModel } from "services/models/hiring/hiringConfig.model";

@Injectable()
export class HiringConfigService extends ServiceBase<HiringConfigModel> {
  constructor(http: HttpService) {
    super("hiringconfig", http);
  }

  createEntity(input: any): HiringConfigModel {
    return HiringConfigModel.create(input);
  }

  getConfigs(companyGroupId: string) {
    return this.http
      .get(`${this.endpoint}/configs/${companyGroupId}`)
      .pipe(
        map((res: any) => {
          return this.createEntity(res.body);
        })
      );
  }
}

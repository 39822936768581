import { ObjectMapper } from 'json-object-mapper';
import { IModel } from './IModel';
import { Model, OptionModel } from './model';

export class CompanyModel extends Model implements IModel<CompanyModel> {
  name: string = '';
  razaoSocial: string = '';
  cnpj: string = '';
  cei: string = '';
  rep: string = '';
  adress: string = '';
  zipCode: string = '';
  latitude: number = 0;
  longitude: number = 0;
  companyId: string = '';
  companyGroupId: string | undefined = undefined;
  companyRootId: string | undefined = undefined;
  headOfficeId: string | undefined = undefined;
  id: string = '';
  enable: boolean = false;

  public toOption(): OptionModel {
    return {
      label: `${this.name} - ${this.OfficeType} `,
      value: this.id,
    } as OptionModel;
  }

  get OfficeType(): string {
    return this.headOfficeId ? 'FILIAL' : 'MATRIZ';
  }

  static create(input: any): CompanyModel {
    return ObjectMapper.deserialize<CompanyModel>(CompanyModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

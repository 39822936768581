import { UserActionType } from "services/enums/userActionType.enum";
import { UserRoleType } from "services/enums/userRoleType.enum";

/* Arquivo de configuração de Role por Página */

const defaultAdminClaims = [UserRoleType.company_adm.value, UserRoleType.company_group_adm.value, UserRoleType.root_adm.value];

const departmentClaims = [...defaultAdminClaims, UserRoleType.personnel_department.value];

export const pageClaims = {
  all: {
    canAdd: [...defaultAdminClaims],
  },
  hourlyBasis: {
    canAdd: [...defaultAdminClaims, UserRoleType.manager.value],
    canAddBatch: [...defaultAdminClaims, UserActionType.can_add_batch.value],
    canReview: [...defaultAdminClaims],
    canViewList: [...defaultAdminClaims, UserActionType.can_view_hourly_basis_list.value]
  },
  CCAndTimeShift: {
    canAdd: [...departmentClaims, UserRoleType.human_resources.value]
  },
  jobTitle: {
    canAdd: departmentClaims
  },
  benefit: {
    canAdd: departmentClaims
  },
  hierarchy: {
    canAdd: departmentClaims,
  },
  pointTime: {
    canAdd: departmentClaims,
  },
  formApplicant: {
    canAdd: [...departmentClaims, UserRoleType.human_resources.value],
    canSesmtValidate: [...defaultAdminClaims, UserRoleType.work_safety.value],
    canDocumentValidate: departmentClaims,
  },
  employee: {
    canAdd: departmentClaims
  },
  vacation: {
    onlyManager: [UserRoleType.manager.value],
    manager: [...defaultAdminClaims, UserRoleType.manager.value],
    vacationManager: [...defaultAdminClaims, UserRoleType.vacation_manager.value]
  },
  insights: {
    canViewAll: [UserRoleType.human_resources.value],
    admin: [...defaultAdminClaims]
  }
}
import { ObjectMapper } from "json-object-mapper";
import { Model } from "../model";
import { IModel } from "../IModel";
import { PetitionsStatusEnum } from "services/enums/petitionsStatus.enum";
import { PetitionsRequestTypeEnum } from "services/enums/petitionsRequestType.enum";
import { BenefitTypeEnum } from "services/enums/benefitsType.enum";

export class PetitionModel extends Model implements IModel<PetitionModel> {
  benefitId: string | undefined = undefined;
  employeeId: string | undefined = undefined;
  employeeName: string = "";
  employeeJobTitle: string = "";
  benefitType: string = "";
  requestType: string = "";
  status: string = "";

  get statusBadge(): string {
    const status = PetitionsStatusEnum.getBy(this.status);
    return `<span class="badge bg-${status?.className} rounded-pill text-wrap">${status?.label}</span>`;
  }

  get customizeEmployee(): string {
    return `<b class="text-primary">${this.employeeName}</b><br>${this.employeeJobTitle}`;
  }

  get requestTypeBadge(): string {
    const status = PetitionsRequestTypeEnum.getBy(this.requestType);
    return `<span class="badge badge-pill badge-light-${status?.className} rounded-pill text-wrap">${status?.label}</span>`;
  }

  get benefitTypeLabel(): string {
    return BenefitTypeEnum.getBy(this.benefitType).label;
  }

  get requestTypeLabel(): string {
    return PetitionsRequestTypeEnum.getBy(this.requestType).label;
  }

  get statusLabel(): string {
    return PetitionsStatusEnum.getBy(this.status).label;
  }

  static create(input: any): PetitionModel {
    return ObjectMapper.deserialize<PetitionModel>(PetitionModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";

export class HiringConfigModel
  extends Model
  implements IModel<HiringConfigModel>
{
  instructionsMp4Url: string = '';
  instructionsPdfUrl: string = '';
  setAdmissionForm: string = '';
  bankNumbers: string[] = [];
  completionDeadline: number = 0;

  static create(input: any): HiringConfigModel {
    return ObjectMapper.deserialize<HiringConfigModel>(
      HiringConfigModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}
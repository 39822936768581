import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";

export class DocumentTypeModel
  extends Model
  implements IModel<DocumentTypeModel>
{
  title: string = "";
  description: string = "";
  twoSides: boolean = false;
  onlyForPCD: boolean = false;
  onlyForMen: boolean = false;

  static create(input: any): DocumentTypeModel {
    return ObjectMapper.deserialize<DocumentTypeModel>(
      DocumentTypeModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

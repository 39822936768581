import { Injectable } from "@angular/core";
import f from 'odata-filter-builder';
import buildQuery from 'odata-query';
import { ServiceBase } from "../servicebase";
import { HttpService } from "../http.service";
import { environment } from "../../../environments/environment";
import { JobTitleModel } from "../models/hiring/jobTitle.model";
import { Observable } from "rxjs";
import { QueryParams } from 'services/models/odata/queryParams';
import { Response } from "services/models/response";
import { JobTitleDocumentModel } from "services/models/hiring/jobTitleDocument.model";
import { map } from "rxjs/operators";

@Injectable()
export class JobTitleService extends ServiceBase<JobTitleModel> {
  constructor(http: HttpService) {
    super("jobtitle", http, environment.apiUrl);
  }

  createEntity(input: any): JobTitleModel {
    return JobTitleModel.create(input);
  }

  loadJobTitlesByTitle(companyId: string, char: string): Observable<JobTitleModel[]> {
    const search = char || '';
    const filter = f()
      .and(f().eq('companyId', companyId).toString().replace(/'/g, ''))
      .and(f().contains('title', search).or(f().contains('code', search))).toString();

    return new Observable<any>((observer) => {
      this.list({
        select: ['title', 'description', 'code', 'salary', 'companyId', 'companyGroupId'],
        orderBy: 'title',
        orderByOrder: 'asc',
        filter: buildQuery({ filter }).replace('?', '&'),
      } as QueryParams).subscribe((res) => {
        observer.next(res.data.filter(x => x.enable));
      }),
        (err) => {
          observer.error(err);
        };
    });
  }

  getFormDocuments(jobtitleId: string): Observable<Response<JobTitleDocumentModel[]>> {
    return this.http
      .get(`${this.endpoint}/${jobtitleId}/form-documents`)
      .pipe(
        map((res) => {
          const response = new Response<JobTitleDocumentModel[]>();
          response.data = res.body.map((pp) => JobTitleDocumentModel.create(pp) as JobTitleDocumentModel);
          response.count = res.headers.get('x-count');
          return response;
        })
      );
  }
}

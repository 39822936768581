import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { HttpService } from "services/http.service";
import { BenefitsEmployeeModel } from "services/models/corporate/benefitsEmployee";
import { QueryParams } from "services/models/odata/queryParams";
import { ServiceBase } from "services/servicebase";
import { Response } from "services/models/response";
import { map } from "rxjs/operators";

@Injectable()
export class BenefitsEmployeeService extends ServiceBase<BenefitsEmployeeModel> {
  constructor(http: HttpService) {
    super("benefit", http, environment.apiUrl);
  }

  createEntity(input: any): BenefitsEmployeeModel {
    return BenefitsEmployeeModel.create(input);
  }

  getBenefits(
    params: QueryParams
  ): Observable<Response<BenefitsEmployeeModel[]>> {
    const query = this.createBuildQuery(params);
    return this.http
      .get(`${this.endpoint}/benefits-employee/odata${query}`)
      .pipe(
        map((res) => {
          const response = new Response<BenefitsEmployeeModel[]>();
          response.data = res.body.map((pp) =>
            BenefitsEmployeeModel.create(pp)
          );
          response.count = res.headers.get("x-count");
          return response;
        })
      );
  }
}
